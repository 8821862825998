/* :local(.customClassPrev) {
  position: absolute;
  top: "50%";
  left: 0;
}
:local(.customClassPrev::before) {
  content: "Left";
} */
.properties__ribon {
    position: absolute;
    right: -5px;
    background: #b24c35;
    color: #fff;
    text-transform: uppercase;
    padding: 3px 15px;
    font-weight: 600;
    top: 18px;
}

.product-item{
  font-weight:600;
  font-size:16px;
  color:#2c3e50;
  /* line-height:1.6px; */
}

.product-address {
  font-weight:100;
  font-size:14px;
  color:grey;
}

/* .product-item:hover {
  font-weight:600;
  font-size:16px;
  color:#fff;
} */

.select-item:hover {
  /* position: absolute; */
  background: #2c3e50;
  border-color: #2c3e50;
  color:#fff;
  /* width: 100%;
  max-width: 100%; */
}

.select-item:hover .product-item {
  color:#fff;
}

.select-item:hover .product-address {
  color:#fff;
}

.select-item:hover .item-price {
  color:#f3bc65;
}

.item-price {
  color:#b24c35;
}

.item-price-amount {
  font-weight:bold;
  font-size:25px;
}

.divider {
  border-top: 3px dotted black;
  background-color:#fff;
  margin-top:10px;
  margin-bottom:10px;
}

.properties__ribon:after {
  position: absolute;
  right: 0;
  bottom: -5px;
  border-top: 5px solid #b24c35;
  width: 5px;
  content: " ";
  display: block;
  border-right: 5px solid #f6f6f6;
}
.customClassPrev {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}
.customClassPrev::before {
  content: "\f053";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  font-size: 30px;
}

.customClassNext {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}
.customClassNext::before {
  content: "\f054";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  font-size: 30px;
  z-index: 1;
}
