

/* .carousel-main {
    width: 1170px;
    height: 600px;
    padding-left: 55px;
}

.carousel-small {
    width: 350px;
    height: 180px;
    padding-left: 54px;
    padding-right: 16px;
} */

/* Desktop 1200 - up */
/* @media (min-width: 992px) and (max-width: 1199px) { */
    .carousel-main {
        width: 80%;
        height: 500px;
        margin: auto;
        /* padding-left: 55px; */
    }

    .carousel-small {
        width: 80%;
        height: 180px;
        margin: auto;
        /* padding-left: 54px; */
        /* padding-right: 16px; */
    }

    .properties-padge {
        position: absolute;
        right: -5px;
        background: #b24c35;
        color: #fff;
        text-transform: uppercase;
        padding: 3px 15px;
        font-weight: 600;
        top: 18px;
        margin: 12px 142px;
        margin: auto;
    }
/* } */
/* small mobile :320px. */
@media (max-width: 620px) {
    .carousel-main {
        width: 620px;
        height: 150px;
        padding-left: 0px;
    }

    .carousel-small {
        width: 80px;
        height: 60px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .properties-padge {
        position: absolute;
        right: -5px;
        background: #b24c35;
        color: #fff;
        text-transform: uppercase;
        padding: 3px 15px;
        font-weight: 600;
        top: 18px;
        margin: 0px 19px;
    }

    .slick-arrow {
        display: none !important;
      }
}

.search-form-bed {
    display: flex;
    justify-content: space-between;
    padding: 0px;
}

.text-field {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 1.6;
}

.ltn__car-dealer-form-box .nice-select {
    
    font-size: 15px; 
    font-weight: 100;
    color: black;
    border: solid 1px #e8e8e8;
    
}

.select {
    border: solid 1px #e8e8e8;
    
}

/* .tab-content {
    width: 50%;
    margin: auto;
} */

.col-lg-3 {
    width: 100%;
}

/* .slick-arrow {
    color:yellow !important;
  } */
.slick-prev:before {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f060";
    color: black;
    font-size: 20px;
    font-style: normal;
    /* width: 50px;
    height: 50px; */
  }
  
  .slick-next:before {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f061";
    color: black;
    font-size: 20px;
    font-style: normal;
  }
  
  
  