.MobileNav{
      display: none;
    }
    .WhatsappButtonFooter{
      background-color: #25d366;
      color: #fff;
      font-weight: bold;
      border-radius:0px;
      padding-top:13px;
    }
    .LiveButtonFooterDiv .LiveButtonFooter{
              background-color: #a32267;
    color: #fff;
    padding-top: 13px;
    text-decoration: none;
    font-weight: 600;
    padding-bottom: 12px;
      
    }

.whatsapp_icon{
   position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    text-decoration: none;
     animation: pulse 1s infinite;
}
.whatsapp_icon:hover .whatsapp_icon_flot{
    color:white;
}
.whatsapp_icon_flot{
  margin-top:16px;
}
.need_help{
    width: 17%;
    height: auto;
    background: #f5eeee;
    color: #000;
    position: fixed;
    left: 80px;
    bottom: 42px;
    padding: 14px;
    border-radius: 6px;
    display: block;
    text-align: center;
    z-index:5;
}
@media(max-width: 1366px){
        .need_help{    
        width: 20%;
    }
}

 @media(max-width: 1280px){
        .need_help{    
        width: 26%;
    }
}
@media(max-width: 768px){
  .need_help{    
    /*width: 65%;*/
    /*height: auto;*/
    /*background: #f5eeee;*/
    /*color: #000;*/
    /*position: fixed;*/
    /*left: 43px;*/
    /*bottom: 42px;*/
    /*padding: 14px;*/
    /*border-radius: 6px;*/
    /*display: block;*/
    /*text-align: center;*/
    display:none;
}
.whatsapp_iconMobile{
  background: #25d366;

}
.whatsapp_iconMobile .whatsapp_icon_flot{
  font-size: 20px;
  color: #fff;
}

.whatsapp_icon{
  display:none;
}
}
@media(max-width: 600px){
.s-media{
    display: none;
  }
  .MobileNav{
      display: block;
     position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 2;
    }
    
    /* .tawk-mobile{
        display:none;
    } */
}

/* .tawk-dropdown{
    display: none !important;
  }
  .tawk-text-bold-4 {
    font-size: 1.5rem !important;
    lline-height: unset !important;
  } */

  .gallery-controls{
    height: 24px;
  }
  .text-style{
    width: 75%;
    margin-left: 14%;
  }
  .h-530{
    height: 570px;
  }
  .btn1{
    color: #fff;
    background-color: #43655A;
    border:1px solid #43655A;
    width: 100%;
  }
  .textArea{
    margin-bottom: 15px !important;
    height: 47px !important;
  }
  .w-25{
    width: 25% !important;
  }
  .project_title_heading{
    color: #fff;
  }
  .services_div a{
    text-decoration: none;
    color: white;
}
#description .Services{
    padding-left: 11px;
}
.services{
  color: #fff;
  padding-left:11px;
}
.serviceCol{
  flex: 0 0 auto;
  width: 25%;
}

@media(max-width: 770px){
  .serviceCol{
    flex: 0 0 auto;
    width: 100%;
    /* margin: 0 10px; */
  }
}
@media(max-width: 570px){
  .serviceCol{
    width: 94%;
    margin: 0 10px;
  }
}

.plr-0{
  padding-left: 0px !important;
  padding-right: 0px !important;
}